import axios from "axios";
import Config from "@/../config.json";

const getPropertyByRole = async (comuna, rol) => {
  try {
    const { data } = await axios(
      `${Config.VUE_APP_URL_OBTAIN_SIMPLE_DOCUMENTS}?comuna=${comuna}&rol=${rol}`
    );

    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: true,
      error,
    };
  }
};

export default getPropertyByRole;

// const getPropertyByRole = {
//   async obtainSimpleDocuments(values) {
//     const data = await axios.post(
//       Config.VUE_APP_URL_OBTAIN_SIMPLE_DOCUMENTS,
//       values
//       )
//       .catch((err) => { return (err.response.data.message) })
//       ;
//     return data.data.simpleDocuments.content;
//   },

// };
// export default getPropertyByRole;
